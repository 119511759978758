import {
  Box,
  Button,
  Icon,
  WrapItem,
  useDisclosure,
  Wrap,
  Flex,
} from "@chakra-ui/react";
import { FC, useState, useMemo, useCallback } from "react";
import { injectParam } from "~/utils/i18n";
import { BiChevronRight } from "react-icons/bi";
import { ModelTypes } from "~/zeus";
import { useGlobalContext } from "~/context/global";
import { useLocaleNavigate, useTranslation } from "~/libs/i18n";
import qs from "qs";
import { convertStringToTimeString } from "~/utils/commons";
import AdvancedSearch from "~/components/search/AdvancedSearch";
import PopoverSelector from "~/components/home/topSection/PopoverSelector";

interface CriteriaState {
  [key: string]: any;
}

const CriteriaSearch: FC = () => {
  const t = useTranslation();
  const navigate = useLocaleNavigate();
  const { setting, district } = useGlobalContext();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const dateOptions = setting?.criteria?.date as ModelTypes["Date"][];
  const audienceOptions = setting?.criteria
    ?.audience as ModelTypes["Audience"][];
  const eventOptions = setting?.criteria
    ?.category as ModelTypes["EventCategory"][];

  const placeOptions = district?.children as ModelTypes["District"][];

  const [criteria, setCriteria] = useState<CriteriaState>({
    time: "",
    audience: "",
    district: "",
    category: "",
  });

  const anyTimeFilter = (
    <PopoverSelector
      options={dateOptions}
      selectedCriteria={criteria?.time.toString()}
      allCriteriaState={criteria}
      setAllCriteria={setCriteria}
      placeholder={t("filters.anyTime")}
      type="time"
    />
  );

  const anyPlaceFilter = useMemo(() => (
    <PopoverSelector
      options={placeOptions}
      selectedCriteria={criteria?.district.toString()}
      placeholder={t("filters.anyWhere")}
      allCriteriaState={criteria}
      setAllCriteria={setCriteria}
      type="district"
    />
  ), [criteria, placeOptions, setCriteria]);

  const anyTargetFilter = useMemo(() => (
    <PopoverSelector
      options={audienceOptions}
      selectedCriteria={criteria?.audience.toString()}
      placeholder={t("filters.you")}
      allCriteriaState={criteria}
      setAllCriteria={setCriteria}
      type="audience"
    />
  ), [audienceOptions, criteria, setCriteria]);

  const anyEventFilter = useMemo(() => (
    <PopoverSelector
      options={eventOptions}
      selectedCriteria={criteria?.category.toString()}
      placeholder={t("filters.events")}
      allCriteriaState={criteria}
      setAllCriteria={setCriteria}
      type="category"
    />
  ), [eventOptions, criteria, setCriteria]);

  const findChildrenByDistrict = useCallback((selectedDistrict: string, districts: any) => {
    if(!selectedDistrict || !districts){
      return ""
    }
  
    const matchingDistrict = districts.find(
      (d: any) => d.value === selectedDistrict
    );
  
    // If a matching district is found, return an array of children's values as strings
    if (matchingDistrict && matchingDistrict.children) {
      return matchingDistrict.children.map((child: any) =>
        child?.value
      )?.toString();
    }
  
    return selectedDistrict;
  }, []);

  return (
    <Box w={["full", "full", "35%"]} pt={4} overflow={"hidden"}>
      <Wrap spacing={1} align="center" fontWeight="bold" fontSize={["xl"]}>
        {injectParam(
          [
            `[[time]] [[place]] [[br]] ${t("filters.letUsHelpYou")} [[br]] ${t(
              "filters.getTheBest"
            )} [[target]] ${t("filters.getAround")} [[event]]`,
          ],
          {
            time: anyTimeFilter,
            place: anyPlaceFilter,
            target: anyTargetFilter,
            event: anyEventFilter,
            br: <Box flex={1} w={["1000px"]} />,
          }
        ).map((comp, i) => (
          <WrapItem key={i}>{comp}</WrapItem>
        ))}
      </Wrap>
      <Flex mt={3} gap={2}>
        <Button
          bgColor="black"
          color="white"
          rounded="full"
          lineHeight={"auto"}
          onClick={() => {
            let params = criteria;
            params = {
              ...params,
              time: convertStringToTimeString(params?.time as string),
              district: findChildrenByDistrict(
                params?.district as string,
                district?.children
              ),
            };

            navigate(`/event?${qs.stringify(params)}`);
          }}
        >
          {t("form.searchNow")}
        </Button>
        <Button
          variant="link"
          rightIcon={<Icon as={BiChevronRight} />}
          onClick={() => onOpen()}
        >
          {t("form.advancedSearch")}
        </Button>
      </Flex>
      <AdvancedSearch
        isOpen={isOpen}
        onClose={onClose}
        dateOptions={dateOptions}
        placeOptions={placeOptions}
        audienceOptions={audienceOptions}
        categoryOptions={eventOptions}
      />
    </Box>
  );
};

export default CriteriaSearch;
