import { useQuery } from "@apollo/client";
import type { FC } from "react";
import { useEffect, useState } from "react";
import AliceCarousel from "react-alice-carousel";

import { AspectRatio, Box, Skeleton, Stack } from "@chakra-ui/react";

import { useParams } from "@remix-run/react";

import BannerSectionQuery from "~/api/query/BannerSectionQuery";
import CustomImage from "~/components/CustomImage";
import { useGlobalContext } from "~/context/global";
import { useLocaleNavigate } from "~/libs/i18n";
import { generateId } from "~/utils/commons";
import type { Banner_region_Input, LocaleInputType, ModelTypes } from "~/zeus";

type Slide = {
  mobile?: ModelTypes["Media"];
  link: string;
  external?: boolean;
  bannerId?: string;
  sizes?: any;
} & ModelTypes["Media"];

const HomeHero: FC<{ height?: any; filters?: string }> = ({ filters }) => {
  const { apolloClient, isDesktop, addBannerViewCount, addEventViewCount } =
    useGlobalContext();
  const { locale, region } = useParams();
  const navigate = useLocaleNavigate();
  const handleDragStart = (e: { preventDefault: () => any }) =>
    e.preventDefault();

  const [slides, setSlides] = useState<Array<Slide>>([]);
  let where: ModelTypes["Banner_where"] = {
    _locations: {
      contains: filters,
    },
    region: {
      equals: region as Banner_region_Input,
    },
  };

  const { data: { GetBannerSection: banners } = {}, loading } = useQuery(
    BannerSectionQuery,
    {
      client: apolloClient,
      variables: {
        locale: locale as LocaleInputType,
        where,
        slug: "home",
      },
      fetchPolicy: "cache-first",
    }
  );

  useEffect(() => {
    if (!loading && banners) {
      const images = banners?.docs;

      if (images) {
        const slides = images.reduce((result: Array<object>, current) => {
          if (current?.desktop?.url || current?.mobile?.url) {
            const slide = {
              ...(isDesktop ? current?.desktop : current?.mobile),
              bannerId: current?.id,
              mobile: {
                ...current?.mobile,
              },
              link: current?.link,
              external: current?.external,
            };
            result?.push(slide);
          }
          return result;
        }, []);

        const bannerIds = slides
          ?.filter((slide: any) => slide && slide)
          ?.map(
            (banner: any) => banner?.bannerId && generateId(banner?.bannerId)
          )
          ?.toString();

        if (bannerIds) {
          addBannerViewCount(bannerIds, "homePageImpression");
        }

        setSlides(slides as Slide[]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (loading) {
    return (
      <Stack w={["full"]}>
        <Skeleton minH={[100, 200]} height={`auto`} />
      </Stack>
    );
  }

  const handleBannerClick = (slide: Slide) => {
    if (!slide?.link) {
      return;
    }

    const bannerId = slide?.bannerId && generateId(slide.bannerId);
    addBannerViewCount(bannerId as string, "homePageClick");

    if (slide?.external) {
      window.open(slide?.link);
      return;
    }
    navigate(slide.link);
  };

  const aliceSlides = slides?.map((slide: Slide, index) => {
    const mobileImage = slide?.mobile?.sizes?.large ?? slide?.mobile;
    const desktopImage = slide?.sizes?.large ?? slide;

    return (
      <Box
        maxW={"1920px"}
        m={`0 auto`}
        height={`auto`}
        key={`${slide?.id}-${index}`}
        onDragStart={handleDragStart}
        onDragEnd={handleDragStart}
        onClick={() => {
          handleBannerClick(slide);
        }}
        cursor={"pointer"}
      >
        <AspectRatio
          ratio={{ base: 2.7 / 1, lg: 3.84 / 1 }}
          borderRadius={"md"}
          overflow={"hidden"}
        >
          <CustomImage
            loading="lazy"
            media={isDesktop ? slide : slide?.mobile ?? slide}
            objectFit="cover"
            onDragStart={handleDragStart}
            role="presentation"
            _hover={{
              opacity: slide?.link ? 0.95 : 1,
            }}
          />
        </AspectRatio>
      </Box>
    );
  });

  return aliceSlides && aliceSlides?.length > 0 ? (
    <Box borderRadius={"md"}>
      <AliceCarousel
        infinite
        autoPlay={aliceSlides?.length > 1}
        autoPlayInterval={5000}
        items={aliceSlides}
        disableButtonsControls
        disableDotsControls={aliceSlides?.length === 1}
      />
    </Box>
  ) : (
    <Box w="100%" bgColor={"#D2D2D2"}></Box>
  );
};

export default HomeHero;
